import { Link, Typography } from '@mui/material';
import { Fragment } from 'react';
import validator from 'validator';

const isURL = (url: string) => {
  return validator.isURL(url, {
    protocols: ['http', 'https'],
    require_protocol: true,
  });
};

export interface BiographyProps {
  text: string;
}

export function Biography({ text }: BiographyProps) {
  return (
    <Typography variant="body2" mt={1}>
      {text.split('\n').map((line, index) => {
        const urlMatch = line.match(/https?:\/\/[^\s]+/);

        if (urlMatch) {
          const [url] = urlMatch;

          if (isURL(url)) {
            const [beforeUrl, afterUrl] = line.split(url);

            return (
              <Fragment key={index}>
                {beforeUrl}
                <Link href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </Link>
                {afterUrl}
                <br />
              </Fragment>
            );
          }
        }

        return (
          <Fragment key={index}>
            {line}
            <br />
          </Fragment>
        );
      })}
    </Typography>
  );
}
