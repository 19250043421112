import {
  addDoc,
  and,
  collection,
  deleteField,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  or,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
  writeBatch,
} from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytes } from 'firebase/storage';
import { calculateRatingDelta } from '../helpers';
import { INITIAL_RATING } from './auth';
import { db, storage } from './firebase';

export const NAME_SEPARATOR = ' · ';
const version = 3;

export interface User {
  id: string;
  names: string[];
  nameLabel?: string;
  associations: string[];
  associationLabel?: string;
  biography?: string;
  rating: number;
  rating7: number;
  rating9: number;
  rating11: number;
  rating13: number;
  numberOfGlassesMedal?: number;
  unconfirmedRating: number;
  unconfirmedRating7: number;
  unconfirmedRating9: number;
  unconfirmedRating11: number;
  unconfirmedRating13: number;
  unconfirmedNumberOfGlassesMedal?: number;
  nameModifiedAt?: number;
  matchesToConfirm: string[];
  latestMatchId?: string;
  profilePicture?: string;
}

export interface LeaderboardUser extends User {
  hasMatch: boolean;
  hasMatch7: boolean;
  hasMatch9: boolean;
  hasMatch11: boolean;
  hasMatch13: boolean;
}

export type UpdateUserRequest = { id: string } & Partial<User>;

export type MatchStatus = 'pending' | 'confirmed' | 'rejected';

export interface Match {
  id: string;
  winner: User;
  loser: User;
  judge: User | null;
  submitter: User | null;
  numberOfGlasses: number;
  status: MatchStatus;
  createdAt: number;
  confirmedAt?: number;
  confirmer?: User;
  medalChallenge?: boolean;
  location?: string;
  event?: string;
  curiosity?: string;
  unranked?: boolean;
  winnerRating?: number;
  loserRating?: number;
  winnerRatingDelta?: number;
  loserRatingDelta?: number;
  winnerRating7?: number;
  loserRating7?: number;
  winnerRating7Delta?: number;
  loserRating7Delta?: number;
  winnerRating9?: number;
  loserRating9?: number;
  winnerRating9Delta?: number;
  loserRating9Delta?: number;
  winnerRating11?: number;
  loserRating11?: number;
  winnerRating11Delta?: number;
  loserRating11Delta?: number;
  winnerRating13?: number;
  loserRating13?: number;
  winnerRating13Delta?: number;
  loserRating13Delta?: number;
}

export interface CreateMatchRequest {
  winner: User;
  loser: User;
  judge: User | null;
  submitter: User | null;
  numberOfGlasses: number;
  createdAt: number | undefined;
  medalChallenge?: boolean;
  location?: string;
  event?: string;
  curiosity?: string;
  unranked?: boolean;
}

export interface MatchResponse {
  winnerId: string;
  loserId: string;
  judgeId: string | null;
  submitterId: string | null;
  numberOfGlasses: number;
  createdAt: number;
  medalChallenge?: boolean;
  location?: string;
  event?: string;
  curiosity?: string;
  unranked?: boolean;
  status: MatchStatus;
}

export interface Mail {
  to: string;
  message: {
    subject: string;
    text: string;
  };
}

const getParsedUser = (user: User) => ({
  ...user,
  nameLabel: user.names.join(NAME_SEPARATOR),
  associationLabel:
    user.associations.length > 0
      ? user.associations.join(NAME_SEPARATOR)
      : undefined,
});

const addMatchToConfirmToParticipants = async (match: Match) => {
  const { winner, loser, judge, submitter } = match;

  const batch = writeBatch(db);

  if (submitter?.id !== winner.id) {
    batch.update(doc(db, 'users', winner.id), {
      matchesToConfirm: [...winner.matchesToConfirm, match.id],
      version,
    });
  }

  if (submitter?.id !== loser.id) {
    batch.update(doc(db, 'users', loser.id), {
      matchesToConfirm: [...loser.matchesToConfirm, match.id],
      version,
    });
  }

  if (judge !== null && submitter?.id !== judge.id) {
    batch.update(doc(db, 'users', judge.id), {
      matchesToConfirm: [...judge.matchesToConfirm, match.id],
      version,
    });
  }

  await batch.commit();
};

const removeMatchToConfirmFromParticipants = async (match: Match) => {
  const { winner, loser, judge } = match;

  const batch = writeBatch(db);

  batch.update(doc(db, 'users', winner.id), {
    matchesToConfirm: winner.matchesToConfirm.filter(id => id !== match.id),
    version,
  });

  batch.update(doc(db, 'users', loser.id), {
    matchesToConfirm: loser.matchesToConfirm.filter(id => id !== match.id),
    version,
  });

  if (judge !== null) {
    batch.update(doc(db, 'users', judge.id), {
      matchesToConfirm: judge.matchesToConfirm.filter(id => id !== match.id),
      version,
    });
  }

  await batch.commit();
};

export const recalculateRatings = async (
  updateDocsFrom: number,
  dry = false,
) => {
  // Revert ratings
  const matchesSnapshot = await getDocs(collection(db, 'matches'));
  const matches = matchesSnapshot.docs
    .map(
      doc =>
        ({ ...doc.data(), matchId: doc.id }) as MatchResponse & {
          matchId: string;
        },
    )
    .filter(
      ({ status, unranked = false }) => status !== 'rejected' && !unranked,
    )
    .toSorted((a, b) => a.createdAt - b.createdAt);

  // Get all users
  const usersSnapshot = await getDocs(collection(db, 'users'));
  const users = usersSnapshot.docs.map(doc => doc.data() as User);

  const userIdToInitialRating = Object.fromEntries(
    users.map(user => [user.id, INITIAL_RATING]),
  );

  const userIdToRating = { ...userIdToInitialRating };
  const userIdToRating7 = { ...userIdToInitialRating };
  const userIdToRating9 = { ...userIdToInitialRating };
  const userIdToRating11 = { ...userIdToInitialRating };
  const userIdToRating13 = { ...userIdToInitialRating };

  const userIdToUnconfirmedRating = { ...userIdToInitialRating };
  const userIdToUnconfirmedRating7 = { ...userIdToInitialRating };
  const userIdToUnconfirmedRating9 = { ...userIdToInitialRating };
  const userIdToUnconfirmedRating11 = { ...userIdToInitialRating };
  const userIdToUnconfirmedRating13 = { ...userIdToInitialRating };

  const userIdToLatestMatchId = Object.fromEntries(
    users.map(user => [user.id, user.latestMatchId]),
  );

  const affectedUserIds = new Set<string>();

  const fromMatch = matchesSnapshot.docs
    .find(doc => doc.data().createdAt === updateDocsFrom)
    ?.data();

  if (fromMatch !== undefined) {
    affectedUserIds.add(fromMatch.winnerId);
    affectedUserIds.add(fromMatch.loserId);
    fromMatch.judgeId !== null && affectedUserIds.add(fromMatch.judgeId);
  }

  const batch = writeBatch(db);

  for (const {
    matchId,
    winnerId,
    loserId,
    judgeId,
    numberOfGlasses,
    createdAt,
    status,
  } of matches) {
    const unconfirmedWinnerRating = userIdToUnconfirmedRating[winnerId];
    const unconfirmedLoserRating = userIdToUnconfirmedRating[loserId];

    const unconfirmedWinnerRatingDelta = calculateRatingDelta(
      unconfirmedWinnerRating,
      unconfirmedLoserRating,
      1,
    );
    const unconfirmedLoserRatingDelta = calculateRatingDelta(
      unconfirmedLoserRating,
      unconfirmedWinnerRating,
      0,
    );

    // Update unconfirmed winner rating
    userIdToUnconfirmedRating[winnerId] =
      unconfirmedWinnerRating + unconfirmedWinnerRatingDelta;

    // Update unconfirmed loser rating
    userIdToUnconfirmedRating[loserId] =
      unconfirmedLoserRating + unconfirmedLoserRatingDelta;

    if (status === 'confirmed') {
      // Update winner rating
      userIdToRating[winnerId] = Math.max(
        0,
        userIdToRating[winnerId] + unconfirmedWinnerRatingDelta,
      );

      // Update loser rating
      userIdToRating[loserId] = Math.max(
        0,
        userIdToRating[loserId] + unconfirmedLoserRatingDelta,
      );
    }

    // Update latest match id
    userIdToLatestMatchId[winnerId] = matchId;
    userIdToLatestMatchId[loserId] = matchId;
    if (judgeId !== null) userIdToLatestMatchId[judgeId] = matchId;

    let rating7Details, rating9Details, rating11Details, rating13Details;

    if (numberOfGlasses === 7) {
      const unconfirmedWinnerRating7 = userIdToUnconfirmedRating7[winnerId];
      const unconfirmedLoserRating7 = userIdToUnconfirmedRating7[loserId];
      const unconfirmedWinnerRating7Delta = calculateRatingDelta(
        unconfirmedWinnerRating7,
        unconfirmedLoserRating7,
        1,
      );
      const unconfirmedLoserRating7Delta = calculateRatingDelta(
        unconfirmedLoserRating7,
        unconfirmedWinnerRating7,
        0,
      );

      rating7Details = {
        winnerRating7: unconfirmedWinnerRating7,
        loserRating7: unconfirmedLoserRating7,
        winnerRating7Delta: unconfirmedWinnerRating7Delta,
        loserRating7Delta: unconfirmedLoserRating7Delta,
      };

      userIdToUnconfirmedRating7[winnerId] =
        unconfirmedWinnerRating7 + unconfirmedWinnerRating7Delta;

      userIdToUnconfirmedRating7[loserId] =
        unconfirmedLoserRating7 + unconfirmedLoserRating7Delta;

      if (status === 'confirmed') {
        userIdToRating7[winnerId] = Math.max(
          0,
          userIdToRating7[winnerId] + unconfirmedWinnerRating7Delta,
        );

        userIdToRating7[loserId] = Math.max(
          0,
          userIdToRating7[loserId] + unconfirmedLoserRating7Delta,
        );
      }
    } else if (numberOfGlasses === 9) {
      const unconfirmedWinnerRating9 = userIdToUnconfirmedRating9[winnerId];
      const unconfirmedLoserRating9 = userIdToUnconfirmedRating9[loserId];
      const unconfirmedWinnerRating9Delta = calculateRatingDelta(
        unconfirmedWinnerRating9,
        unconfirmedLoserRating9,
        1,
      );
      const unconfirmedLoserRating9Delta = calculateRatingDelta(
        unconfirmedLoserRating9,
        unconfirmedWinnerRating9,
        0,
      );

      rating9Details = {
        winnerRating9: unconfirmedWinnerRating9,
        loserRating9: unconfirmedLoserRating9,
        winnerRating9Delta: unconfirmedWinnerRating9Delta,
        loserRating9Delta: unconfirmedLoserRating9Delta,
      };

      userIdToUnconfirmedRating9[winnerId] =
        unconfirmedWinnerRating9 + unconfirmedWinnerRating9Delta;

      userIdToUnconfirmedRating9[loserId] =
        unconfirmedLoserRating9 + unconfirmedLoserRating9Delta;

      if (status === 'confirmed') {
        userIdToRating9[winnerId] = Math.max(
          0,
          userIdToRating9[winnerId] + unconfirmedWinnerRating9Delta,
        );

        userIdToRating9[loserId] = Math.max(
          0,
          userIdToRating9[loserId] + unconfirmedLoserRating9Delta,
        );
      }
    } else if (numberOfGlasses === 11) {
      const unconfirmedWinnerRating11 = userIdToUnconfirmedRating11[winnerId];
      const unconfirmedLoserRating11 = userIdToUnconfirmedRating11[loserId];
      const unconfirmedWinnerRating11Delta = calculateRatingDelta(
        unconfirmedWinnerRating11,
        unconfirmedLoserRating11,
        1,
      );
      const unconfirmedLoserRating11Delta = calculateRatingDelta(
        unconfirmedLoserRating11,
        unconfirmedWinnerRating11,
        0,
      );

      rating11Details = {
        winnerRating11: unconfirmedWinnerRating11,
        loserRating11: unconfirmedLoserRating11,
        winnerRating11Delta: unconfirmedWinnerRating11Delta,
        loserRating11Delta: unconfirmedLoserRating11Delta,
      };

      userIdToUnconfirmedRating11[winnerId] =
        unconfirmedWinnerRating11 + unconfirmedWinnerRating11Delta;

      userIdToUnconfirmedRating11[loserId] =
        unconfirmedLoserRating11 + unconfirmedLoserRating11Delta;

      if (status === 'confirmed') {
        userIdToRating11[winnerId] = Math.max(
          0,
          userIdToRating11[winnerId] + unconfirmedWinnerRating11Delta,
        );

        userIdToRating11[loserId] = Math.max(
          0,
          userIdToRating11[loserId] + unconfirmedLoserRating11Delta,
        );
      }
    } else if (numberOfGlasses === 13) {
      const unconfirmedWinnerRating13 = userIdToUnconfirmedRating13[winnerId];
      const unconfirmedLoserRating13 = userIdToUnconfirmedRating13[loserId];
      const unconfirmedWinnerRating13Delta = calculateRatingDelta(
        unconfirmedWinnerRating13,
        unconfirmedLoserRating13,
        1,
      );
      const unconfirmedLoserRating13Delta = calculateRatingDelta(
        unconfirmedLoserRating13,
        unconfirmedWinnerRating13,
        0,
      );

      rating13Details = {
        winnerRating13: unconfirmedWinnerRating13,
        loserRating13: unconfirmedLoserRating13,
        winnerRating13Delta: unconfirmedWinnerRating13Delta,
        loserRating13Delta: unconfirmedLoserRating13Delta,
      };

      userIdToUnconfirmedRating13[winnerId] =
        unconfirmedWinnerRating13 + unconfirmedWinnerRating13Delta;

      userIdToUnconfirmedRating13[loserId] =
        unconfirmedLoserRating13 + unconfirmedLoserRating13Delta;

      if (status === 'confirmed') {
        userIdToRating13[winnerId] = Math.max(
          0,
          userIdToRating13[winnerId] + unconfirmedWinnerRating13Delta,
        );

        userIdToRating13[loserId] = Math.max(
          0,
          userIdToRating13[loserId] + unconfirmedLoserRating13Delta,
        );
      }
    }

    // Write rating details to match
    if (createdAt >= updateDocsFrom) {
      batch.update(doc(db, 'matches', matchId), {
        winnerRating: unconfirmedWinnerRating,
        loserRating: unconfirmedLoserRating,
        winnerRatingDelta: unconfirmedWinnerRatingDelta,
        loserRatingDelta: unconfirmedLoserRatingDelta,
        ...rating7Details,
        ...rating9Details,
        ...rating11Details,
        ...rating13Details,
        version,
      });

      affectedUserIds.add(winnerId);
      affectedUserIds.add(loserId);
      judgeId !== null && affectedUserIds.add(judgeId);
    }
  }

  // Write new ratings to users
  for (const userId of affectedUserIds) {
    batch.update(doc(db, 'users', userId), {
      rating: userIdToRating[userId],
      rating7: userIdToRating7[userId],
      rating9: userIdToRating9[userId],
      rating11: userIdToRating11[userId],
      rating13: userIdToRating13[userId],
      unconfirmedRating: userIdToUnconfirmedRating[userId],
      unconfirmedRating7: userIdToUnconfirmedRating7[userId],
      unconfirmedRating9: userIdToUnconfirmedRating9[userId],
      unconfirmedRating11: userIdToUnconfirmedRating11[userId],
      unconfirmedRating13: userIdToUnconfirmedRating13[userId],
      latestMatchId: userIdToLatestMatchId[userId],
      version,
    });
  }

  if (!dry) {
    await batch.commit();
  } else {
    console.log('--  Dry run --');
    console.log('This would have updated the following ratings:');
  }

  // Print updated ratings
  for (const userId of affectedUserIds) {
    const user = users.find(user => user.id === userId)!;

    const {
      rating,
      rating7,
      rating9,
      rating11,
      rating13,
      unconfirmedRating,
      unconfirmedRating7,
      unconfirmedRating9,
      unconfirmedRating11,
      unconfirmedRating13,
    } = user;

    const newRating = userIdToRating[userId];
    const newRating7 = userIdToRating7[userId];
    const newRating9 = userIdToRating9[userId];
    const newRating11 = userIdToRating11[userId];
    const newRating13 = userIdToRating13[userId];
    const newUnconfirmedRating = userIdToUnconfirmedRating[userId];
    const newUnconfirmedRating7 = userIdToUnconfirmedRating7[userId];
    const newUnconfirmedRating9 = userIdToUnconfirmedRating9[userId];
    const newUnconfirmedRating11 = userIdToUnconfirmedRating11[userId];
    const newUnconfirmedRating13 = userIdToUnconfirmedRating13[userId];

    for (const [type, before, after] of [
      ['', rating, newRating],
      ['7', rating7, newRating7],
      ['9', rating9, newRating9],
      ['11', rating11, newRating11],
      ['13', rating13, newRating13],
      ['~', unconfirmedRating, newUnconfirmedRating],
      ['~7', unconfirmedRating7, newUnconfirmedRating7],
      ['~9', unconfirmedRating9, newUnconfirmedRating9],
      ['~11', unconfirmedRating11, newUnconfirmedRating11],
      ['~13', unconfirmedRating13, newUnconfirmedRating13],
    ]) {
      if (before !== after) {
        console.log(
          `${user.names.join(NAME_SEPARATOR)}${type && ` (${type})`}: ${before} -> ${after}`,
        );
      }
    }
  }
};

/** Users */

export async function createUser(user: User) {
  await setDoc(doc(db, 'users', user.id), { ...user, version });

  await addDoc(collection(db, 'mail'), {
    to: 'machalvan@hotmail.com',
    message: {
      subject: 'Capsat: Ny användare',
      text: `${user.names.join(NAME_SEPARATOR)}${user.associations.length > 0 ? ` (${user.associations.join(NAME_SEPARATOR)})` : ''} har registrerat sig!`,
    },
  });
}

export async function getUsers() {
  const { docs } = await getDocs(collection(db, 'users'));
  return docs.map(doc => {
    const user = doc.data() as User;
    return getParsedUser(user);
  });
}

export async function getLeaderboardUsers() {
  const users = await getUsers();

  const matchesSnapshot = await getDocs(collection(db, 'matches'));
  const matches = matchesSnapshot.docs.map(doc => doc.data() as MatchResponse);

  return users.map(user => {
    const userMatches = matches.filter(
      match =>
        match.status === 'confirmed' &&
        match.unranked !== true &&
        (match.winnerId === user.id || match.loserId === user.id),
    );

    return {
      ...user,
      hasMatch: userMatches.length > 0,
      hasMatch7: userMatches.some(match => match.numberOfGlasses === 7),
      hasMatch9: userMatches.some(match => match.numberOfGlasses === 9),
      hasMatch11: userMatches.some(match => match.numberOfGlasses === 11),
      hasMatch13: userMatches.some(match => match.numberOfGlasses === 13),
    };
  });
}

export async function getUser(id: string) {
  const docSnap = await getDoc(doc(db, 'users', id));
  const user = docSnap.data() as User | undefined;

  return user !== undefined ? getParsedUser(user) : null;
}

export async function updateUser({ id, ...updatedUser }: UpdateUserRequest) {
  await updateDoc(doc(db, 'users', id), { ...updatedUser, version });
}

export async function onUserStateChanged(
  userId: string,
  setUser: (user: User | null) => void,
) {
  return onSnapshot(doc(db, 'users', userId), doc => {
    const user = doc.data() as User;
    setUser(getParsedUser(user));
  });
}

/** Matches */

export async function createMatch(match: CreateMatchRequest) {
  const {
    winner: _winner,
    loser: _loser,
    judge,
    submitter,
    createdAt,
    ...rest
  } = match;
  const { numberOfGlasses, unranked, medalChallenge } = rest;

  // Refetch users to get the latest ratings
  const winner = await getUser(match.winner.id);
  const loser = await getUser(match.loser.id);

  if (winner === null || loser === null) return;

  const unconfirmedWinnerRatingDelta = !unranked
    ? calculateRatingDelta(winner.unconfirmedRating, loser.unconfirmedRating, 1)
    : 0;
  const unconfirmedLoserRatingDelta = !unranked
    ? calculateRatingDelta(loser.unconfirmedRating, winner.unconfirmedRating, 0)
    : 0;

  const newWinnerRating =
    winner.unconfirmedRating + unconfirmedWinnerRatingDelta;
  const newLoserRating = loser.unconfirmedRating + unconfirmedLoserRatingDelta;

  let rating7Details, newWinnerRating7, newLoserRating7;
  let rating9Details, newWinnerRating9, newLoserRating9;
  let rating11Details, newWinnerRating11, newLoserRating11;
  let rating13Details, newWinnerRating13, newLoserRating13;

  if (numberOfGlasses === 7) {
    rating7Details = {
      winnerRating7: winner.unconfirmedRating7,
      loserRating7: loser.unconfirmedRating7,
      winnerRating7Delta: !unranked
        ? calculateRatingDelta(
            winner.unconfirmedRating7,
            loser.unconfirmedRating7,
            1,
          )
        : 0,
      loserRating7Delta: !unranked
        ? calculateRatingDelta(
            loser.unconfirmedRating7,
            winner.unconfirmedRating7,
            0,
          )
        : 0,
    };

    newWinnerRating7 = {
      unconfirmedRating7:
        winner.unconfirmedRating7 + rating7Details.winnerRating7Delta,
    };

    newLoserRating7 = {
      unconfirmedRating7:
        loser.unconfirmedRating7 + rating7Details.loserRating7Delta,
    };
  } else if (numberOfGlasses === 9) {
    rating9Details = {
      winnerRating9: winner.unconfirmedRating9,
      loserRating9: loser.unconfirmedRating9,
      winnerRating9Delta: !unranked
        ? calculateRatingDelta(
            winner.unconfirmedRating9,
            loser.unconfirmedRating9,
            1,
          )
        : 0,
      loserRating9Delta: !unranked
        ? calculateRatingDelta(
            loser.unconfirmedRating9,
            winner.unconfirmedRating9,
            0,
          )
        : 0,
    };

    newWinnerRating9 = {
      unconfirmedRating9:
        winner.unconfirmedRating9 + rating9Details.winnerRating9Delta,
    };

    newLoserRating9 = {
      unconfirmedRating9:
        loser.unconfirmedRating9 + rating9Details.loserRating9Delta,
    };
  } else if (numberOfGlasses === 11) {
    rating11Details = {
      winnerRating11: winner.unconfirmedRating11,
      loserRating11: loser.unconfirmedRating11,
      winnerRating11Delta: !unranked
        ? calculateRatingDelta(
            winner.unconfirmedRating11,
            loser.unconfirmedRating11,
            1,
          )
        : 0,
      loserRating11Delta: !unranked
        ? calculateRatingDelta(
            loser.unconfirmedRating11,
            winner.unconfirmedRating11,
            0,
          )
        : 0,
    };

    newWinnerRating11 = {
      unconfirmedRating11:
        winner.unconfirmedRating11 + rating11Details.winnerRating11Delta,
    };

    newLoserRating11 = {
      unconfirmedRating11:
        loser.unconfirmedRating11 + rating11Details.loserRating11Delta,
    };
  } else if (numberOfGlasses === 13) {
    rating13Details = {
      winnerRating13: winner.unconfirmedRating13,
      loserRating13: loser.unconfirmedRating13,
      winnerRating13Delta: !unranked
        ? calculateRatingDelta(
            winner.unconfirmedRating13,
            loser.unconfirmedRating13,
            1,
          )
        : 0,
      loserRating13Delta: !unranked
        ? calculateRatingDelta(
            loser.unconfirmedRating13,
            winner.unconfirmedRating13,
            0,
          )
        : 0,
    };

    newWinnerRating13 = {
      unconfirmedRating13:
        winner.unconfirmedRating13 + rating13Details.winnerRating13Delta,
    };

    newLoserRating13 = {
      unconfirmedRating13:
        loser.unconfirmedRating13 + rating13Details.loserRating13Delta,
    };
  }

  const batch = writeBatch(db);

  const matchDocRef = doc(collection(db, 'matches'));

  batch.set(matchDocRef, {
    status: 'pending',
    createdAt: createdAt ?? Date.now(),
    winnerId: winner.id,
    loserId: loser.id,
    judgeId: judge?.id ?? null,
    submitterId: submitter?.id ?? null,
    winnerRating: winner.unconfirmedRating,
    loserRating: loser.unconfirmedRating,
    winnerRatingDelta: unconfirmedWinnerRatingDelta,
    loserRatingDelta: unconfirmedLoserRatingDelta,
    ...rating7Details,
    ...rating9Details,
    ...rating11Details,
    ...rating13Details,
    ...rest,
    version,
  });

  if (createdAt === undefined) {
    batch.update(doc(db, 'users', winner.id), {
      latestMatchId: matchDocRef.id,
      unconfirmedRating: newWinnerRating,
      ...newWinnerRating7,
      ...newWinnerRating9,
      ...newWinnerRating11,
      ...newWinnerRating13,
      ...(medalChallenge && {
        unconfirmedNumberOfGlassesMedal: numberOfGlasses,
      }),
      version,
    });

    batch.update(doc(db, 'users', loser.id), {
      latestMatchId: matchDocRef.id,
      unconfirmedRating: newLoserRating,
      ...newLoserRating7,
      ...newLoserRating9,
      ...newLoserRating11,
      ...newLoserRating13,
      ...(medalChallenge && {
        unconfirmedNumberOfGlassesMedal: deleteField(),
      }),
      version,
    });

    if (match.judge !== null) {
      batch.update(doc(db, 'users', match.judge.id), {
        latestMatchId: matchDocRef.id,
        version,
      });
    }
  }

  await batch.commit();

  if (createdAt !== undefined) {
    await recalculateRatings(createdAt);
  }

  await addMatchToConfirmToParticipants({
    ...match,
    id: matchDocRef.id,
    status: 'pending',
    createdAt: createdAt ?? Date.now(),
  });

  await addDoc(collection(db, 'mail'), {
    to: 'machalvan@hotmail.com',
    message: {
      subject: 'Capsat: Ny match',
      text: `En match har registrerats där ${winner.nameLabel} vann mot ${loser.nameLabel}!`,
    },
  });
}

export async function onUserMatchesSnapshot(
  userId: string,
  setUserMatches: (matches: Match[]) => void,
) {
  return onSnapshot(
    query(
      collection(db, 'matches'),
      and(
        where('status', '!=', 'rejected'),
        or(
          where('winnerId', '==', userId),
          where('loserId', '==', userId),
          where('judgeId', '==', userId),
        ),
      ),
      orderBy('createdAt', 'desc'),
    ),
    { includeMetadataChanges: true },
    async snapshot => {
      if (snapshot.metadata.fromCache) return;

      const userMatches = await Promise.all(
        snapshot.docs.map(async doc => {
          const { winnerId, loserId, judgeId, submitterId, ...match } =
            doc.data() as MatchResponse;

          const [winner, loser, judge, submitter] = await Promise.all([
            getUser(winnerId),
            getUser(loserId),
            judgeId !== null ? getUser(judgeId) : null,
            submitterId !== null ? getUser(submitterId) : null,
          ]);

          if (winner === null || loser === null) return null;

          return { ...match, id: doc.id, winner, loser, judge, submitter };
        }),
      );

      setUserMatches(
        userMatches
          .filter(match => match !== null)
          .toSorted((a, b) => b.createdAt - a.createdAt),
      );
    },
  );
}

export async function getRankedUserMatches(userId: string) {
  const { docs } = await getDocs(
    query(
      collection(db, 'matches'),
      and(
        where('status', '==', 'confirmed'),
        or(where('winnerId', '==', userId), where('loserId', '==', userId)),
      ),
    ),
  );

  return docs
    .map(doc => doc.data() as MatchResponse)
    .filter(({ unranked = false }) => !unranked);
}

export async function rejectMatch(match: Match) {
  const { medalChallenge } = match;

  const batch = writeBatch(db);

  batch.update(doc(db, 'matches', match.id), {
    status: 'rejected',
    version,
  });

  batch.update(doc(db, 'users', match.winner.id), {
    ...(medalChallenge && {
      unconfirmedNumberOfGlassesMedal:
        match.winner.numberOfGlassesMedal ?? deleteField(),
    }),
    version,
  });

  batch.update(doc(db, 'users', match.loser.id), {
    ...(medalChallenge && {
      unconfirmedNumberOfGlassesMedal:
        match.loser.numberOfGlassesMedal ?? deleteField(),
    }),
    version,
  });

  await batch.commit();

  await recalculateRatings(match.createdAt);

  await removeMatchToConfirmFromParticipants(match);
}

export async function confirmMatch(match: Match) {
  const { numberOfGlasses, medalChallenge } = match;

  // Refetch users to get the latest ratings
  const winner = await getUser(match.winner.id);
  const loser = await getUser(match.loser.id);

  if (winner === null || loser === null) return;

  const winnerRatingDelta = match.winnerRatingDelta ?? 0;
  const loserRatingDelta = match.loserRatingDelta ?? 0;

  const newWinnerRating = Math.max(0, winner.rating + winnerRatingDelta);
  const newLoserRating = Math.max(0, loser.rating + loserRatingDelta);

  let newWinnerRating7, newLoserRating7;
  let newWinnerRating9, newLoserRating9;
  let newWinnerRating11, newLoserRating11;
  let newWinnerRating13, newLoserRating13;

  if (numberOfGlasses === 7) {
    const winnerRating7Delta = match.winnerRating7Delta ?? 0;
    const loserRating7Delta = match.loserRating7Delta ?? 0;

    newWinnerRating7 = {
      rating7: Math.max(0, winner.rating7 + winnerRating7Delta),
    };
    newLoserRating7 = {
      rating7: Math.max(0, loser.rating7 + loserRating7Delta),
    };
  } else if (numberOfGlasses === 9) {
    const winnerRating9Delta = match.winnerRating9Delta ?? 0;
    const loserRating9Delta = match.loserRating9Delta ?? 0;

    newWinnerRating9 = {
      rating9: Math.max(0, winner.rating9 + winnerRating9Delta),
    };
    newLoserRating9 = {
      rating9: Math.max(0, loser.rating9 + loserRating9Delta),
    };
  } else if (numberOfGlasses === 11) {
    const winnerRating11Delta = match.winnerRating11Delta ?? 0;
    const loserRating11Delta = match.loserRating11Delta ?? 0;

    newWinnerRating11 = {
      rating11: Math.max(0, winner.rating11 + winnerRating11Delta),
    };
    newLoserRating11 = {
      rating11: Math.max(0, loser.rating11 + loserRating11Delta),
    };
  } else if (numberOfGlasses === 13) {
    const winnerRating13Delta = match.winnerRating13Delta ?? 0;
    const loserRating13Delta = match.loserRating13Delta ?? 0;

    newWinnerRating13 = {
      rating13: Math.max(0, winner.rating13 + winnerRating13Delta),
    };
    newLoserRating13 = {
      rating13: Math.max(0, loser.rating13 + loserRating13Delta),
    };
  }

  const batch = writeBatch(db);

  batch.update(doc(db, 'matches', match.id), {
    status: 'confirmed',
    confirmedAt: Date.now(),
    confirmerId: match.confirmer?.id,
    version,
  });

  batch.update(doc(db, 'users', winner.id), {
    rating: newWinnerRating,
    ...newWinnerRating7,
    ...newWinnerRating9,
    ...newWinnerRating11,
    ...newWinnerRating13,
    ...(medalChallenge && { numberOfGlassesMedal: numberOfGlasses }),
    version,
  });

  batch.update(doc(db, 'users', loser.id), {
    rating: newLoserRating,
    ...newLoserRating7,
    ...newLoserRating9,
    ...newLoserRating11,
    ...newLoserRating13,
    ...(medalChallenge && { numberOfGlassesMedal: deleteField() }),
    version,
  });

  await batch.commit();

  await removeMatchToConfirmFromParticipants(match);
}

/** Storage */
export async function uploadProfilePicture(user: User, file: File) {
  const storageRef = ref(storage, `profile-pictures/${user.id}`);
  await uploadBytes(storageRef, file);

  return await getDownloadURL(storageRef);
}
